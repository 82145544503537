body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.reim-image {
  max-width: 90vw;
  width: 400px;
  height: 400px;
  object-fit: contain;
  background: rgba(0, 0, 0, 0.1);
}

.navbar a,
.navbar button {
  color: rgba(140, 208, 240, 0.75);
  font-weight: bold;
  border: none;
  background: transparent;
  padding-left: 0;
  padding-right: 0;
}
.navbar a:hover,
.navbar button:hover {
  color: lightskyblue;
}

.navbar {
  height: 70px;
}

@media (max-width: 576px) {
  .navbar {
    height: 100px;
  }
}

.mainmenu button {
  min-height: 10vh;
  min-width: 30vw;
  margin-top: 20px;
  font-size: 150%;
  font-weight: bold;
}
@media (max-width: 576px) {
  .mainmenu button {
    min-height: 15vh;
    min-width: 80vw;
    margin-top: 10px;
  }
}

.particular-row-wrapper {
}

@media (max-width: 1200px) {
  .particular-row-wrapper {
    overflow-x: scroll;
    min-height: 40vh;
    padding-bottom: 80px;
  }
}

.particular-row {
  min-width: 1020px;
  /* overflow-x: scroll; */
}

.ReactTable {
  max-height: 100%;
}
